import React from "react"
import { Link } from "gatsby"

const Layout = ({ children, location }) => {
  const isHomePage = location?.pathname === "/"

  return (
    <div className="w-full min-h-screen bg-white px-8 py-4 font-mono">
      <div className="max-w-4xl">
        <header className="mb-8 border-b border-gray-400 pb-4">
          <Link to="/" className="text-black hover:text-gray-700">
            <h1 className="text-2xl mb-2">Eric Migicovsky</h1>
          </Link>
        </header>
        <main>{children}</main>
        <footer className="mt-16 pt-4 border-t border-gray-400">
          <nav>
            {!isHomePage && (
              <>
                <Link to="/" className="text-indigo-600 hover:text-indigo-800 mr-4">
                  Home
                </Link>
                <a 
                  href="https://twitter.com/ericmigi" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-indigo-600 hover:text-indigo-800 mr-4"
                >
                  Twitter
                </a>
                <a
                  href="https://bsky.app/profile/ericmigi.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-indigo-600 hover:text-indigo-800 mr-4"
                >
                  Bluesky
                </a>
                <a 
                  href="https://www.chartwellspeakers.com/speaker/eric-migicovsky/" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-indigo-600 hover:text-indigo-800 mr-4"
                >
                  Speaking
                </a>
                <a 
                  href="mailto:eric@ericmigi.com" 
                  className="text-indigo-600 hover:text-indigo-800"
                >
                  Contact
                </a>
              </>
            )}
          </nav>
        </footer>
      </div>
    </div>
  )
}

export default Layout
